import React from 'react';
import PropTypes from 'prop-types';

const BgTaxes = ({ color, id }) => (
  <svg width="361" height="358" viewBox="0 0 361 358" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="185.761" cy="175.5" r="175" fill={`url(#${id}paint0_linear)`}/>
    <path d="M349.261 182C349.261 278.652 271.356 357 175.261 357C79.1659 357 1.26074 278.652 1.26074 182C1.26074 85.3471 79.1659 6.99963 175.261 6.99963C271.356 6.99963 349.261 85.3471 349.261 182Z" stroke={`url(#${id}paint1_linear)`}/>
    <path d="M238.908 322.647H241.115V324.854H238.908V322.647ZM224.192 322.647H226.399V324.854H224.192V322.647ZM224.192 307.931H226.399V310.138H224.192V307.931ZM238.908 307.931H241.115V310.138H238.908V307.931ZM253.623 307.931H255.831V310.138H253.623V307.931ZM253.623 322.647H255.831V324.854H253.623V322.647ZM253.623 337.362H255.831V339.57H253.623V337.362ZM238.908 337.362H241.115V339.57H238.908V337.362ZM224.192 337.362H226.399V339.57H224.192V337.362ZM209.476 337.362H211.684V339.57H209.476V337.362ZM209.476 322.647H211.684V324.854H209.476V322.647ZM209.476 307.931H211.684V310.138H209.476V307.931ZM209.476 293.215H211.684V295.423H209.476V293.215ZM224.192 293.215H226.399V295.423H224.192V293.215ZM238.908 293.215H241.115V295.423H238.908V293.215ZM253.623 293.215H255.831V295.423H253.623V293.215ZM268.339 293.215H270.547V295.423H268.339V293.215ZM268.339 307.931H270.547V310.138H268.339V307.931ZM268.339 322.647H270.547V324.854H268.339V322.647ZM268.339 337.362H270.547V339.57H268.339V337.362ZM268.339 352.078H270.547V354.285H268.339V352.078ZM253.623 352.078H255.831V354.285H253.623V352.078ZM238.908 352.078H241.115V354.285H238.908V352.078ZM224.192 352.078H226.399V354.285H224.192V352.078ZM209.476 352.078H211.684V354.285H209.476V352.078ZM194.761 352.078H196.968V354.285H194.761V352.078ZM194.761 337.362H196.968V339.57H194.761V337.362ZM194.761 322.647H196.968V324.854H194.761V322.647ZM194.761 307.931H196.968V310.138H194.761V307.931ZM194.761 293.215H196.968V295.423H194.761V293.215ZM194.761 278.5H196.968V280.707H194.761V278.5ZM209.476 278.5H211.684V280.707H209.476V278.5ZM224.192 278.5H226.399V280.707H224.192V278.5ZM238.908 278.5H241.115V280.707H238.908V278.5ZM253.623 278.5H255.831V280.707H253.623V278.5ZM268.339 278.5H270.547V280.707H268.339V278.5Z" fill={color}/>
    <defs>
      <linearGradient id={`${id}paint0_linear`} x1="429.261" y1="-72.0004" x2="351.761" y2="170" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
      <linearGradient id={`${id}paint1_linear`} x1="245.261" y1="23.4996" x2="43.2607" y2="182.5" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

BgTaxes.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};

BgTaxes.dafaultProps = {
  color: '#000',
};

export default BgTaxes;
