import React from 'react';

const IconBank = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5939 3.08619C15.8524 2.97127 16.1476 2.97127 16.4061 3.08619L28.4061 8.41952C28.7673 8.58002 29 8.93814 29 9.33333V12C29 12.5523 28.5523 13 28 13H4C3.44772 13 3 12.5523 3 12V9.33333C3 8.93814 3.23273 8.58002 3.59386 8.41952L15.5939 3.08619ZM5 9.98321V11H27V9.98321L16 5.09432L5 9.98321Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 14.3333C6.55228 14.3333 7 14.781 7 15.3333V20.6667C7 21.219 6.55228 21.6667 6 21.6667C5.44772 21.6667 5 21.219 5 20.6667V15.3333C5 14.781 5.44772 14.3333 6 14.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26 14.3333C26.5523 14.3333 27 14.781 27 15.3333V20.6667C27 21.219 26.5523 21.6667 26 21.6667C25.4477 21.6667 25 21.219 25 20.6667V15.3333C25 14.781 25.4477 14.3333 26 14.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 14.3333C13.219 14.3333 13.6667 14.781 13.6667 15.3333V20.6667C13.6667 21.219 13.219 21.6667 12.6667 21.6667C12.1144 21.6667 11.6667 21.219 11.6667 20.6667V15.3333C11.6667 14.781 12.1144 14.3333 12.6667 14.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3333 14.3333C19.8856 14.3333 20.3333 14.781 20.3333 15.3333V20.6667C20.3333 21.219 19.8856 21.6667 19.3333 21.6667C18.781 21.6667 18.3333 21.219 18.3333 20.6667V15.3333C18.3333 14.781 18.781 14.3333 19.3333 14.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 24C3 23.4477 3.44772 23 4 23H28C28.5523 23 29 23.4477 29 24V28C29 28.5523 28.5523 29 28 29H4C3.44772 29 3 28.5523 3 28V24ZM5 25V27H27V25H5Z" fill="#31AEFF"/>
  </svg>
);

export default IconBank;
