import React from 'react';
import PropTypes from 'prop-types';

const BgNotifications = ({ color, id }) => (
  <svg width="536" height="358" viewBox="0 0 536 358" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="361" cy="175.081" r="175" fill={`url(#${id}paint0_linear)`}/>
    <path d="M524.5 181.581C524.5 278.234 446.595 356.581 350.5 356.581C254.405 356.581 176.5 278.234 176.5 181.581C176.5 84.9285 254.405 6.58098 350.5 6.58098C446.595 6.58098 524.5 84.9285 524.5 181.581Z" stroke={`url(#${id}paint1_linear)`}/>
    <path d="M44.1471 76.228H46.3544V78.4354H44.1471V76.228ZM29.4314 76.228H31.6387V78.4354H29.4314V76.228ZM29.4314 61.5124H31.6387V63.7197H29.4314V61.5124ZM44.1471 61.5124H46.3544V63.7197H44.1471V61.5124ZM58.8627 61.5124H61.0701V63.7197H58.8627V61.5124ZM58.8627 76.228H61.0701V78.4354H58.8627V76.228ZM58.8627 90.9437H61.0701V93.1511H58.8627V90.9437ZM44.1471 90.9437H46.3544V93.1511H44.1471V90.9437ZM29.4314 90.9437H31.6387V93.1511H29.4314V90.9437ZM14.7157 90.9437H16.923V93.1511H14.7157V90.9437ZM14.7157 76.228H16.923V78.4354H14.7157V76.228ZM14.7157 61.5124H16.923V63.7197H14.7157V61.5124ZM14.7157 46.7967H16.923V49.004H14.7157V46.7967ZM29.4314 46.7967H31.6387V49.004H29.4314V46.7967ZM44.1471 46.7967H46.3544V49.004H44.1471V46.7967ZM58.8627 46.7967H61.0701V49.004H58.8627V46.7967ZM73.5784 46.7967H75.7858V49.004H73.5784V46.7967ZM73.5784 61.5124H75.7858V63.7197H73.5784V61.5124ZM73.5784 76.228H75.7858V78.4354H73.5784V76.228ZM73.5784 90.9437H75.7858V93.1511H73.5784V90.9437ZM73.5784 105.659H75.7858V107.867H73.5784V105.659ZM58.8627 105.659H61.0701V107.867H58.8627V105.659ZM44.1471 105.659H46.3544V107.867H44.1471V105.659ZM29.4314 105.659H31.6387V107.867H29.4314V105.659ZM14.7157 105.659H16.923V107.867H14.7157V105.659ZM0 105.659H2.20735V107.867H0V105.659ZM0 90.9437H2.20735V93.1511H0V90.9437ZM0 76.228H2.20735V78.4354H0V76.228ZM0 61.5124H2.20735V63.7197H0V61.5124ZM0 46.7967H2.20735V49.004H0V46.7967ZM0 32.081H2.20735V34.2883H0V32.081ZM14.7157 32.081H16.923V34.2883H14.7157V32.081ZM29.4314 32.081H31.6387V34.2883H29.4314V32.081ZM44.1471 32.081H46.3544V34.2883H44.1471V32.081ZM58.8627 32.081H61.0701V34.2883H58.8627V32.081ZM73.5784 32.081H75.7858V34.2883H73.5784V32.081Z" fill={color}/>
    <defs>
      <linearGradient id={`${id}paint0_linear`} x1="604.5" y1="-72.419" x2="527" y2="169.581" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
      <linearGradient id={`${id}paint1_linear`} x1="420.5" y1="23.081" x2="218.5" y2="182.081" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

BgNotifications.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};

BgNotifications.dafaultProps = {
  color: '#000',
};

export default BgNotifications;
