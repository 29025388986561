import React from 'react';

const IconGraphic = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9991 3.375C18.6204 3.375 19.1241 3.87868 19.1241 4.5V17.6145C19.1241 17.9052 19.0116 18.1845 18.8102 18.3941L9.26421 28.3256C8.83365 28.7735 8.12148 28.7876 7.67353 28.3571C7.22558 27.9265 7.21149 27.2144 7.64205 26.7664L16.8741 17.1615V4.5C16.8741 3.87868 17.3778 3.375 17.9991 3.375Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.82664 11.6567C5.08248 11.0905 5.74888 10.8389 6.31508 11.0947L18.4651 16.5847C19.0313 16.8405 19.2829 17.5069 19.027 18.0731C18.7712 18.6393 18.1048 18.8909 17.5386 18.6351L5.3886 13.1451C4.8224 12.8893 4.5708 12.2229 4.82664 11.6567Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.7504 9.24955C21.9177 4.41682 14.0823 4.41681 9.24956 9.24956L9.24955 9.24956C4.41682 14.0823 4.41681 21.9177 9.24956 26.7504C14.0823 31.5832 21.9177 31.5832 26.7504 26.7504C31.583 21.9179 31.5832 14.0829 26.7509 9.25007C26.7508 9.2499 26.7506 9.24973 26.7504 9.24955ZM28.3414 7.65857C34.0528 13.37 34.0528 22.63 28.3414 28.3414C22.63 34.0528 13.37 34.0528 7.65857 28.3414C1.94715 22.63 1.94714 13.37 7.65857 7.65856C13.37 1.94715 22.63 1.94714 28.3414 7.65857Z" fill="#31AEFF"/>
  </svg>
);

export default IconGraphic;
