import React from 'react';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';

import BgTaxes from './BgTaxes';
import MainImage from '../../static/images/ilustracao-impostos.png';
import LazyImage from './LazyImage';

const ImageTaxes = ({ color, id }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      height: '390px',
      textAlign: 'right',

      '& svg': {
        marginRight: '190px',
      },

      '& img': {
        position: 'absolute',
        top: '15px',
        right: 0,
      },

      '@media screen and (max-width: 1200px)': {
        height: '280px',
        textAlign: 'center',

        '& svg': {
          height: '100%',
          margin: 0,
        },
  
        '& img': {
          height: '100%',
          right: '50%',
          transform: 'translateX(50%)',
        },
      },

      '@media screen and (max-width: 600px)': {
        height: '180px',

        '& svg': {
          width: '100%',
        },
        '& img': {
          width: '90%',
        },
      },
    },
  }));
  const classes = useStyles();
  const globalTheme = useTheme();

  return (
    <Box className={classes.root}>
      <BgTaxes id={id} color={globalTheme.palette[color].main} />
      {/* <img src={MainImage} alt="Ilustração de gerenciamento de impostos da plataforma Conube" /> */}
      <LazyImage img={MainImage} alt="Ilustração de gerenciamento de impostos da plataforma Conube" />
    </Box>
  );
};

ImageTaxes.propTypes = {
  color: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ImageTaxes;
