import React from 'react';

const IconPaper = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33464 5C4.41359 5 3.66797 5.74562 3.66797 6.66667V25.3333C3.66797 26.2544 4.41359 27 5.33464 27H26.668C27.589 27 28.3346 26.2544 28.3346 25.3333V6.66667C28.3346 5.74562 27.589 5 26.668 5H5.33464ZM1.66797 6.66667C1.66797 4.64105 3.30902 3 5.33464 3H26.668C28.6936 3 30.3346 4.64105 30.3346 6.66667V25.3333C30.3346 27.359 28.6936 29 26.668 29H5.33464C3.30902 29 1.66797 27.359 1.66797 25.3333V6.66667Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66797 10.6667C1.66797 10.1144 2.11568 9.66667 2.66797 9.66667H29.3346C29.8869 9.66667 30.3346 10.1144 30.3346 10.6667C30.3346 11.219 29.8869 11.6667 29.3346 11.6667H2.66797C2.11568 11.6667 1.66797 11.219 1.66797 10.6667Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9274 16.7406C12.4955 15.3086 10.1739 15.3086 8.74194 16.7406C7.31001 18.1725 7.31001 20.4941 8.74194 21.926C10.1739 23.358 12.4955 23.358 13.9274 21.926C15.3593 20.4941 15.3593 18.1725 13.9274 16.7406ZM15.3416 15.3264C13.1286 13.1134 9.54071 13.1134 7.32773 15.3264C5.11475 17.5393 5.11475 21.1273 7.32773 23.3402C9.54071 25.5532 13.1286 25.5532 15.3416 23.3402C17.5546 21.1273 17.5546 17.5393 15.3416 15.3264Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3346 13.6667C11.8869 13.6667 12.3346 14.1144 12.3346 14.6667V18.9191L15.3417 21.9262C15.7323 22.3168 15.7321 22.9497 15.3416 23.3402C14.9511 23.7308 14.3181 23.731 13.9275 23.3404L10.6275 20.0404C10.44 19.8529 10.3346 19.5986 10.3346 19.3333V14.6667C10.3346 14.1144 10.7824 13.6667 11.3346 13.6667Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0013 16C19.0013 15.4477 19.449 15 20.0013 15H24.0013C24.5536 15 25.0013 15.4477 25.0013 16C25.0013 16.5523 24.5536 17 24.0013 17H20.0013C19.449 17 19.0013 16.5523 19.0013 16Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0013 20C19.0013 19.4477 19.449 19 20.0013 19H22.668C23.2203 19 23.668 19.4477 23.668 20C23.668 20.5523 23.2203 21 22.668 21H20.0013C19.449 21 19.0013 20.5523 19.0013 20Z" fill="#31AEFF"/>
  </svg>
);

export default IconPaper;
