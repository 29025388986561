import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const FuncionalityButton = ({ icon, text, selected, handleClick, color }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '35px 0 30px',
      borderRadius: '15px',
      textAlign: 'center',
      marginLeft: '10px',

      '& svg, & svg path': {
        fill: theme.palette[color].main,
      },

      '& .MuiTypography-root': {
        color: theme.palette.midGrey.main,
        transition: '0.2s',
        marginTop: '15px',
      },

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.contrastText,
        transition: '0.2s',

        '& .MuiTypography-root': {
          color: theme.palette[color].main,
          transition: '0.2s',
        },
      },
    },
    selected: {
      boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: theme.palette.secondary.contrastText,
      transition: '0.1s',

      '& .MuiTypography-root': {
        color: theme.palette[color].main,
        transition: '0.1s',
      },
    },
    default: {
      backgroundColor: '#0000',
      transition: '0.1s',
    },
  }));
  const classes = useStyles();

  return (
    <Box
      onClick={handleClick}
      className={`${classes.root} ${selected ? classes.selected : classes.default}`}
    >
      {icon}
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};

FuncionalityButton.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
};

export default FuncionalityButton;
