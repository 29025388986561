import React from 'react';

const IconCoinBag = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.2161 8.02235C23.6641 8.45287 23.6783 9.16504 23.2477 9.61303C21.642 11.2839 19.2317 11.625 18.1211 11.625C17.4998 11.625 16.9961 11.1213 16.9961 10.5C16.9961 9.87868 17.4998 9.375 18.1211 9.375C18.9815 9.375 20.6422 9.07707 21.6254 8.05397C22.056 7.60598 22.7681 7.59183 23.2161 8.02235Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7639 4.43253C20.212 4.86292 20.2264 5.57509 19.796 6.0232C19.612 6.21476 19.444 6.53251 19.3217 6.99382C19.2016 7.44693 19.1451 7.96451 19.1296 8.47528C19.1142 8.98147 19.1399 9.45021 19.17 9.79397C19.185 9.96479 19.2008 10.1021 19.2125 10.1946C19.2184 10.2408 19.2232 10.2756 19.2264 10.2977L19.2298 10.3209L19.2302 10.3238C19.2303 10.3239 19.2302 10.3236 19.2302 10.3238C19.2302 10.3236 19.2302 10.3238 18.1191 10.4999L17.008 10.676C17.1052 11.2897 17.6816 11.7083 18.2952 11.611C18.9087 11.5138 19.3272 10.9373 19.2302 10.3238M18.1191 10.4999C17.008 10.676 17.008 10.6763 17.008 10.676L17.0076 10.6734L17.0069 10.6694L17.0051 10.6573L16.9992 10.6174C16.9944 10.584 16.9879 10.537 16.9805 10.478C16.9655 10.3602 16.9464 10.1936 16.9286 9.99026C16.8932 9.58571 16.8619 9.02507 16.8806 8.40702C16.8992 7.79353 16.968 7.092 17.1468 6.41729C17.3235 5.75078 17.6282 5.03204 18.1732 4.4646C18.6036 4.01649 19.3158 4.00213 19.7639 4.43253" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7869 8.02235C13.2349 7.59183 13.947 7.60598 14.3776 8.05397C15.3608 9.07707 17.0215 9.375 17.8819 9.375C18.5032 9.375 19.0069 9.87868 19.0069 10.5C19.0069 11.1213 18.5032 11.625 17.8819 11.625C16.7713 11.625 14.361 11.2839 12.7553 9.61303C12.3247 9.16504 12.3389 8.45287 12.7869 8.02235Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2363 4.43253C16.6844 4.00213 17.3966 4.01649 17.827 4.4646C18.372 5.03204 18.6767 5.75078 18.8534 6.41729C19.0323 7.092 19.101 7.79353 19.1196 8.40702C19.1384 9.02507 19.1071 9.58571 19.0716 9.99026C19.0538 10.1936 19.0347 10.3602 19.0198 10.478C19.0123 10.537 19.0058 10.584 19.001 10.6174L18.9951 10.6573L18.9933 10.6694L18.9927 10.6734L18.9924 10.6749C18.9924 10.6752 18.9923 10.676 17.8897 10.5013L18.9924 10.6749C18.8952 11.2885 18.3187 11.7083 17.705 11.611C17.0913 11.5138 16.6727 10.9374 16.77 10.3238L17.8811 10.4999C16.77 10.3238 16.77 10.3236 16.77 10.3238L16.7704 10.3209L16.7738 10.2977C16.777 10.2756 16.7818 10.2408 16.7877 10.1946C16.7994 10.1021 16.8153 9.96479 16.8302 9.79397C16.8603 9.45021 16.886 8.98147 16.8706 8.47528C16.8551 7.96451 16.7986 7.44692 16.6785 6.99382C16.5562 6.53251 16.3882 6.21476 16.2043 6.0232C15.7739 5.57509 15.7882 4.86292 16.2363 4.43253Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.65698 13.1804C5.77803 11.4346 7.04825 9.3749 9.0015 9.3749H26.9985C28.952 9.3749 30.2223 11.4351 29.3427 13.181L28.5885 14.6804C28.1427 15.5643 27.2366 16.1249 26.244 16.1249H9.7575C8.76615 16.1249 7.85848 15.5666 7.41206 14.6816L6.65698 13.1804ZM26.9985 11.6249H9.0015C8.72298 11.6249 8.54127 11.9197 8.66671 12.1688L9.42094 13.6682C9.42087 13.6681 9.421 13.6683 9.42094 13.6682C9.48459 13.794 9.61499 13.8749 9.7575 13.8749H26.244C26.3853 13.8749 26.5148 13.7955 26.579 13.6684C26.579 13.6684 26.579 13.6683 26.579 13.6684L27.333 12.1694C27.4584 11.9204 27.277 11.6249 26.9985 11.6249Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1742 4.46302C19.5694 3.0107 21.8516 3.0107 23.2468 4.46302C24.6176 5.88993 24.6176 8.18588 23.2468 9.61278C22.8164 10.0608 22.1042 10.0751 21.6561 9.6447C21.2081 9.21426 21.1938 8.50209 21.6242 8.05403C22.1584 7.49793 22.1584 6.57788 21.6242 6.02178C21.1144 5.49111 20.3066 5.49111 19.7968 6.02178C19.3664 6.46985 18.6542 6.48413 18.2061 6.0537C17.7581 5.62326 17.7438 4.91109 18.1742 4.46302Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2032 6.02178C15.6934 5.49111 14.8856 5.49111 14.3758 6.02178C13.8416 6.57788 13.8416 7.49793 14.3758 8.05403C14.8062 8.50209 14.7919 9.21426 14.3439 9.6447C13.8958 10.0751 13.1836 10.0608 12.7532 9.61278C11.3824 8.18588 11.3824 5.88993 12.7532 4.46302C14.1484 3.0107 16.4306 3.0107 17.8258 4.46302C18.2562 4.91109 18.2419 5.62326 17.7939 6.0537C17.3458 6.48413 16.6336 6.46985 16.2032 6.02178Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2987 13.8807C26.8306 13.5597 27.5221 13.7306 27.8432 14.2626L31.6337 20.5431C32.2826 21.6187 32.625 22.8503 32.625 24.1064C32.625 25.7591 32.0316 27.3561 30.9529 28.6086L29.5531 30.2319C28.2427 31.7521 26.3355 32.6249 24.33 32.6249H11.67C9.66454 32.6249 7.7574 31.7521 6.44701 30.2321C6.44704 30.2321 6.44697 30.232 6.44701 30.2321L5.04751 28.6091C3.96883 27.3566 3.375 25.7591 3.375 24.1064C3.375 22.8511 3.71537 21.6225 4.36484 20.5476C5.46009 18.7307 7.34847 15.6019 8.15685 14.2626C8.47791 13.7306 9.16941 13.5597 9.70134 13.8808C10.2333 14.2018 10.4042 14.8933 10.0832 15.4252C9.27461 16.7648 7.38629 19.8935 6.29148 21.7097C5.85517 22.4317 5.625 23.2589 5.625 24.1064C5.625 25.2195 6.02446 26.2953 6.75149 27.1397L8.15099 28.7627C9.03359 29.7866 10.3185 30.3749 11.67 30.3749H24.33C25.6815 30.3749 26.9663 29.7867 27.8489 28.7629L29.2481 27.1403C29.248 27.1403 29.2481 27.1402 29.2481 27.1403C29.9753 26.2958 30.375 25.2196 30.375 24.1064C30.375 23.2595 30.1443 22.4301 29.7073 21.7057C29.7074 21.7058 29.7073 21.7057 29.7073 21.7057L25.9168 15.4252C25.5958 14.8933 25.7667 14.2018 26.2987 13.8807Z" fill="#31AEFF"/>
  </svg>
);

export default IconCoinBag;
