import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IconArrow from '../atoms/IconArrow';
import { Box, Typography, Collapse, makeStyles, useTheme } from '@material-ui/core';
import WomanThinking from '../atoms/WomanThinking';
import ShowInMobile from '../atoms/ShowInMobile';

const FAQList = ({ questions, color }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: '40% auto',
      gap: '20px',

      '@media screen and (max-width: 959px)': {
        gridTemplateColumns: 'auto',
        gap: '15px',
      },
    },
    description: {
      '& .MuiTypography-h6': {
        color: theme.palette[color].main,
      },
      '& .MuiTypography-h4': {
        width: '60%',
        marginBottom: '30px',
      },
      '& svg': {
        width: '100%',
      },

      '@media screen and (max-width: 959px)': {
        '& .MuiTypography-h6': {
          fontSize: '16px',
          letterSpacing: '0.3em',
          textTransform: 'uppercase',
        },
        '& .MuiTypography-h4': {
          width: '100%',
          marginBottom: 0,
          fontWeight: 300,
          fontSize: '22px',
          lineHeight: '30px',
        },
        '& svg': {
          display: 'none',
        },
      },
    },
    questions: {
      '& :nth-child(1)': {
        borderTop: '0 !important',
      },
      '& .question': {
        borderTop: '0.6px solid #CDCADE',

        '& .MuiCollapse-wrapperInner': {
          paddingBottom: '15px',
        },
      },
      '& .question-title': {
        display: 'grid',
        gridTemplateColumns: '90% auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 0',

        '& .MuiTypography-root': {
          color: theme.palette.text.primary,
          transition: '0.2s',
        },
        '& svg path': {
          stroke: `${theme.palette[color].main}`,
        },

        '&:hover': {
          cursor: 'pointer',

          '& .MuiTypography-root': {
            color: theme.palette[color].main,
            transition: '0.1s',
          },
        },
      },
      '& .default svg': {
        transform: 'rotate(0deg)',
        transition: '0.1s',
      },
      '& .chosen': {
        '& .MuiTypography-root': {
          color: theme.palette.text.primary,
          transition: '0.2s',
        },

        '& svg': {
          transform: 'rotate(180deg)',
          transition: '0.1s',
        },
      },
    },
  }));
  const classes = useStyles();

  const globalTheme = useTheme();
  const [chosenQuestionIdx, setChosenQuestionIdx] = useState('');

  const handleClick = (idx) => {
    if (idx === chosenQuestionIdx)
      setChosenQuestionIdx('');
    else
      setChosenQuestionIdx(idx);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.description}>
        <Typography variant="h6" component="h2">
          Perguntas frequentes
        </Typography>
        <Typography variant="h4" component="h3">
          Não fique com dúvidas
        </Typography>
        <ShowInMobile breakpoint={959}>
          <WomanThinking color={globalTheme.palette[color].main} />
        </ShowInMobile>
      </Box>

      <Box className={classes.questions}>
        {questions.map((question, idx) => (
          <Box key={question.name} className="question">
            <Box
              onClick={() => handleClick(idx)}
              className={`question-title ${chosenQuestionIdx === idx ? 'chosen' : 'default'}`}
            >
              <Typography variant="body1" component="h4">
                {question.name}
              </Typography>
              <IconArrow />
            </Box>

            <Collapse in={chosenQuestionIdx === idx} timeout="auto" unmountOnExit>
              <Typography variant="body1">{question.acceptedAnswer.text}</Typography>
            </Collapse>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

FAQList.propTypes = {
  questions: PropTypes.array.isRequired,
  color: PropTypes.string,
};

FAQList.defaultProps = {
  color: 'primary',
};

export default FAQList;
