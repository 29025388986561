import React from 'react';

const IconCalendar = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 3.375C23.1213 3.375 23.625 3.87868 23.625 4.5V7.5C23.625 8.12132 23.1213 8.625 22.5 8.625C21.8787 8.625 21.375 8.12132 21.375 7.5V4.5C21.375 3.87868 21.8787 3.375 22.5 3.375Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3.375C11.1213 3.375 11.625 3.87868 11.625 4.5V7.5C11.625 8.12132 11.1213 8.625 10.5 8.625C9.87868 8.625 9.375 8.12132 9.375 7.5V4.5C9.375 3.87868 9.87868 3.375 10.5 3.375Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.375 13.5C3.375 12.8787 3.87868 12.375 4.5 12.375H28.5C29.1213 12.375 29.625 12.8787 29.625 13.5C29.625 14.1213 29.1213 14.625 28.5 14.625H4.5C3.87868 14.625 3.375 14.1213 3.375 13.5Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.375 10.5C3.375 7.39318 5.89318 4.875 9 4.875H24C27.1068 4.875 29.625 7.39318 29.625 10.5V15C29.625 15.6213 29.1213 16.125 28.5 16.125C27.8787 16.125 27.375 15.6213 27.375 15V10.5C27.375 8.63582 25.8642 7.125 24 7.125H9C7.13582 7.125 5.625 8.63582 5.625 10.5V24C5.625 25.8642 7.13582 27.375 9 27.375H15C15.6213 27.375 16.125 27.8787 16.125 28.5C16.125 29.1213 15.6213 29.625 15 29.625H9C5.89318 29.625 3.375 27.1068 3.375 24V10.5Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.0895 22.8495C26.7108 22.8495 27.2145 23.3532 27.2145 23.9745V25.98L28.7483 26.9156C29.2788 27.2391 29.4465 27.9314 29.1229 28.4618C28.7994 28.9923 28.1071 29.16 27.5767 28.8364L25.5037 27.5719C25.1688 27.3677 24.9645 27.0037 24.9645 26.6115V23.9745C24.9645 23.3532 25.4682 22.8495 26.0895 22.8495Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.2546 20.625C23.226 20.6213 20.625 23.2204 20.625 26.25C20.625 29.3575 23.1437 31.875 26.25 31.875C29.3575 31.875 31.875 29.3563 31.875 26.25C31.875 23.1448 29.3595 20.6275 26.2546 20.625ZM26.2569 18.375C21.9838 18.37 18.375 21.9778 18.375 26.25C18.375 30.6005 21.9013 34.125 26.25 34.125C30.6005 34.125 34.125 30.5987 34.125 26.25C34.125 21.9033 30.6038 18.3788 26.2569 18.375ZM26.2569 18.375C26.257 18.375 26.2568 18.375 26.2569 18.375V18.375Z" fill="#31AEFF"/>
  </svg>
);

export default IconCalendar;
