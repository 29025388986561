import React from 'react';

const IconCreditCard = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33203 10.1333C2.33203 9.58105 2.77975 9.13333 3.33203 9.13333H28.6654C29.2176 9.13333 29.6654 9.58105 29.6654 10.1333C29.6654 10.6856 29.2176 11.1333 28.6654 11.1333H3.33203C2.77975 11.1333 2.33203 10.6856 2.33203 10.1333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9987 6C5.07765 6 4.33203 6.74562 4.33203 7.66667V19.6667C4.33203 20.5877 5.07765 21.3333 5.9987 21.3333H9.33203C9.88432 21.3333 10.332 21.781 10.332 22.3333C10.332 22.8856 9.88432 23.3333 9.33203 23.3333H5.9987C3.97308 23.3333 2.33203 21.6923 2.33203 19.6667V7.66667C2.33203 5.64105 3.97308 4 5.9987 4H25.9987C28.0243 4 29.6654 5.64105 29.6654 7.66667V19.6667C29.6654 21.6923 28.0243 23.3333 25.9987 23.3333H22.6654C22.1131 23.3333 21.6654 22.8856 21.6654 22.3333C21.6654 21.781 22.1131 21.3333 22.6654 21.3333H25.9987C26.9197 21.3333 27.6654 20.5877 27.6654 19.6667V7.66667C27.6654 6.74562 26.9197 6 25.9987 6H5.9987Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 22.3333C8.33203 18.1144 11.7797 14.6667 15.9987 14.6667C20.2176 14.6667 23.6654 18.1144 23.6654 22.3333C23.6654 26.5523 20.2176 30 15.9987 30C13.4959 30 11.275 28.7816 9.87849 26.9255M15.9987 16.6667C12.8843 16.6667 10.332 19.219 10.332 22.3333C10.332 23.5998 10.759 24.7707 11.476 25.7222C12.5169 27.1058 14.1603 28 15.9987 28C19.1131 28 21.6654 25.4477 21.6654 22.3333C21.6654 19.219 19.1131 16.6667 15.9987 16.6667ZM8.33203 22.3333C8.33203 24.0586 8.91577 25.6477 9.87849 26.9255L8.33203 22.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9987 17.3333C16.551 17.3333 16.9987 17.781 16.9987 18.3333V19.3333C16.9987 19.8856 16.551 20.3333 15.9987 20.3333C15.4464 20.3333 14.9987 19.8856 14.9987 19.3333V18.3333C14.9987 17.781 15.4464 17.3333 15.9987 17.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9987 24.3333C16.551 24.3333 16.9987 24.781 16.9987 25.3333V26.3333C16.9987 26.8856 16.551 27.3333 15.9987 27.3333C15.4464 27.3333 14.9987 26.8856 14.9987 26.3333V25.3333C14.9987 24.781 15.4464 24.3333 15.9987 24.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4027 20.332C15.1797 20.332 14.9987 20.5129 14.9987 20.736C14.9987 20.9205 15.1247 21.0832 15.3055 21.1289C15.3054 21.1289 15.3056 21.129 15.3055 21.1289L17.1795 21.5994C18.2466 21.8685 18.9987 22.8287 18.9987 23.932C18.9987 25.2583 17.9236 26.336 16.5947 26.336H15.4987C14.5701 26.336 13.8244 25.8203 13.409 25.1395C13.1214 24.668 13.2704 24.0526 13.7419 23.765C14.2134 23.4774 14.8287 23.6264 15.1164 24.0979C15.2184 24.265 15.3579 24.336 15.4987 24.336H16.5947C16.8178 24.336 16.9987 24.155 16.9987 23.932C16.9987 23.7475 16.8727 23.5848 16.6919 23.5391C16.692 23.5391 16.6918 23.539 16.6919 23.5391L14.8179 23.0686C13.7508 22.7995 12.9987 21.8393 12.9987 20.736C12.9987 19.4071 14.0764 18.332 15.4027 18.332H16.4987C17.4254 18.332 18.1695 18.8458 18.585 19.5247C18.8733 19.9957 18.7251 20.6113 18.254 20.8996C17.783 21.1879 17.1674 21.0397 16.8791 20.5687C16.7772 20.4022 16.6386 20.332 16.4987 20.332H15.4027Z" fill="#31AEFF"/>
  </svg>
);

export default IconCreditCard;
