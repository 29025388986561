import React from 'react';
import {
  Box, Typography, makeStyles, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Img from 'gatsby-image';
import StyledButton from '../atoms/StyledButton';
import IconQuotes from '../atoms/IconQuotes';
import { useDrawerContext } from '../../contexts/DrawerContext';
import LazyImage from '../atoms/LazyImage';

const FuncionalityItem = ({
  funcionality, color, hasCustomerComment, taNaModa,
}) => {
  const { setOpenDrawer } = useDrawerContext();

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      alignItems: hasCustomerComment ? 'center' : 'flex-start',

      '& .description': {
        color: theme.palette.midGrey.main,
        marginTop: '20px',
      },

      '& .MuiButton-root': {
        width: '280px',
      },

      '@media screen and (max-width: 1200px)': {
        gridTemplateColumns: '100%',
        marginBottom: '50px',

        '& .MuiButton-root': {
          width: '100%',
        },
      },
    },
    informations: {
      width: '75%',

      '@media screen and (max-width: 1200px)': {
        width: '100%',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '15px',
        display: 'none',

        '& path': {
          fill: theme.palette[color].main,
        },
      },

      '@media screen and (max-width: 1200px)': {
        '& svg': { display: 'block' },
      },
    },
    properties: {
      display: 'grid',
      gridTemplateColumns: '45% 45%',
      gap: '10%',
      margin: '30px 0 60px',

      '& .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',

        '& .MuiTypography-root': {
          marginLeft: '10px',
        },

        '& svg': {
          width: '60px',

          '& path': {
            fill: theme.palette[color].main,
          },
        },
      },

      '@media screen and (max-width: 1200px)': {
        gridTemplateColumns: '100%',
        gap: '15px',
      },
    },
    customerComment: {
      marginTop: '24px',
      borderTop: `0.6px solid ${theme.palette.action.disabledBackground}`,
      padding: '24px 0',

      '& .comment': {
        margin: '24px 0',
      },

      '& .customer-info': {
        display: 'grid',
        gridTemplateColumns: 'min-content auto min-content',
        alignItems: 'center',
        gap: '14px',

        '& .customer-picture': {
          width: '48px',
        },

        '& .MuiTypography-body1': {
          color: theme.palette.text.primary,
        },
      },

      '@media screen and (max-width: 1200px)': {
        borderBottom: `0.6px solid ${theme.palette.action.disabledBackground}`,
      },
    },
    imageDesktop: {
      '@media screen and (max-width: 1200px)': {
        display: 'none',
      },
    },
    imageMobile: {
      '@media screen and (min-width: 1200px)': {
        display: 'none',
      },
    },
  }));
  const classes = useStyles();
  const theme = useTheme();

  const getButtonColor = () => {
    if (color === 'info') return 'secondary';
    if (color === 'secondary') return 'info';
    return color;
  };

  return (
    <Box key={funcionality.title} className={classes.root}>
      <Box>
        <Box className={classes.informations}>
          <Box className={classes.title}>
            {funcionality.mainIcon}
            <Typography variant="h6" component="h3">{funcionality.title}</Typography>
          </Box>
          <Typography variant="h6" className="description" component="h4">
            {funcionality.description}
          </Typography>

          <Box className={classes.imageMobile}>
            {funcionality.imageMobile}
          </Box>

          <Box className={classes.properties}>
            <Box>
              {funcionality.icon1}
              <Typography variant="body1" component="h5">
                {funcionality.property1}
              </Typography>
            </Box>

            <Box>
              {funcionality.icon2}
              <Typography variant="body1" component="h5">
                {funcionality.property2}
              </Typography>
            </Box>
          </Box>

          {taNaModa ? (
            <StyledButton
              text="QUERO APROVEITAR"
              onClick={() => setOpenDrawer(true)}
              variant="tnm"
              color="tnm"
            />
          ) : (
            <StyledButton
              text="Saiba quanto custa"
              color={getButtonColor()}
              variant="contained"
              component="a"
              href="/quanto-custa/"
            />
          )}
        </Box>

        {hasCustomerComment && (
          <Box className={classes.customerComment}>
            <IconQuotes color={theme.palette[color].main} />
            <Typography variant="body1" className="comment">
              {funcionality.customer.comment}
            </Typography>

            <Box className="customer-info">
              {/* <img
                src={funcionality.customer.src}
                alt={`${funcionality.customer.name} da empresa ${funcionality.customer.company}`}
                className="customer-picture"
              /> */}
              <div>
                <LazyImage>
                  <Img
                    fixed={funcionality.customer.src}
                    alt={`${funcionality.customer.name} da empresa ${funcionality.customer.company}`}
                    // className="customer-picture"
                    // className="customer-picure"
                    style={{ width: '48px', height: '48px' }}
                  />
                </LazyImage>
              </div>
              <Box>
                <Typography variant="body1">{funcionality.customer.name}</Typography>
                <Typography variant="body2">{funcionality.customer.company}</Typography>
              </Box>
              {/* <img
                src={funcionality.customer.logo}
                alt={`${funcionality.customer.name} da empresa ${funcionality.customer.company}`}
              /> */}
              <div>
                <LazyImage
                  src={funcionality.customer.logo}
                  alt={`${funcionality.customer.name} da empresa ${funcionality.customer.company}`}
                />
              </div>
            </Box>
          </Box>
        )}
      </Box>

      <Box className={classes.imageDesktop}>
        {funcionality.image}
      </Box>
    </Box>
  );
};

FuncionalityItem.defaultProps = {
  hasCustomerComment: false,
};

FuncionalityItem.propTypes = {
  funcionality: PropTypes.shape().isRequired,
  color: PropTypes.string.isRequired,
  hasCustomerComment: PropTypes.bool,
  taNaModa: PropTypes.bool.isRequired,
};

export default FuncionalityItem;
