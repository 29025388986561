import React, { useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import WomanPhoto from '../../static/images/mulher-camisa-laranja.png';
import ManPhoto from '../../static/images/homem-idoso-camisa-azul.png';
import LazyImage from './LazyImage';
import useIntersector from './UseIntersector';

const background = {
  'blue': {
    backgroundColor: '#44A0DB',
    backgroundImage: `url(/images/form-background-pattern-blue.png)`,
  },
  'green': {
    backgroundColor: '#48AE9F',
    backgroundImage: `url(/images/form-background-pattern-green.png)`,
  },
};

const FormBackground = ({ color, children, ...rest }) => {

  const reference = useRef();
  const isIntersecting = useIntersector({ ref: reference });

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: 'fit-content',
      
      '@media screen and (max-width: 959px)': {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 0 0',
        borderRadius: '0px 24px 24px 0px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        // ...background[color],
        backgroundColor: background[color].backgroundColor,
        backgroundImage: !isIntersecting ? '' :  background[color].backgroundImage,
      },
    },
    background: {
      width: '68%',
      height: '100%',
      position: 'absolute',
      borderRadius: '0px 24px 24px 0px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundColor: background[color].backgroundColor,
      backgroundImage: !isIntersecting ? '' :  background[color].backgroundImage,

      '@media screen and (max-width: 959px)': {
        display: 'none',
      },
    },
    image: {
      position: 'absolute',
      bottom: 0,
      left: '28%',

      '@media screen and (max-width: 1600px)': {
        left: '20%',
      },
      '@media screen and (max-width: 1400px)': {
        left: '5%',
      },
    },
    imageMobile: {
      width: '70%',

      '@media screen and (min-width: 959px)': {
        display: 'none',
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <div ref={reference} />
      <Box className={classes.root} {...rest}>
        <Box className={classes.background}>
          {/* <img
            src={color === 'blue' ? WomanPhoto : ManPhoto}
            alt={color === 'blue' ? 'Mulher com camisa laranja' : 'Homem idoso com camisa azul'}
            className={classes.image}
          /> */}
          <LazyImage 
            src={color === 'blue' ? WomanPhoto : ManPhoto}
            alt={color === 'blue' ? 'Mulher com camisa laranja' : 'Homem idoso com camisa azul'}
            className={classes.image}
          />
        </Box>
        {children}
        {/* <img
          src={color === 'blue' ? WomanPhoto : ManPhoto}
          alt={color === 'blue' ? 'Mulher com camisa laranja' : 'Homem idoso com camisa azul'}
          className={classes.imageMobile}
        /> */}
        <LazyImage
          src={color === 'blue' ? WomanPhoto : ManPhoto}
          alt={color === 'blue' ? 'Mulher com camisa laranja' : 'Homem idoso com camisa azul'}
          className={classes.imageMobile}
        />
      </Box>
    </>
  );
};

FormBackground.defaultProps = {
  color: 'blue',
  children: <></>,
  rest: {},
};

FormBackground.propTypes = {
  color: PropTypes.string,
  children: PropTypes.element,
  rest: PropTypes.shape()
};

export default FormBackground;
