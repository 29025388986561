import React from 'react';

const IconBill = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66667 5C7.74562 5 7 5.74562 7 6.66667V25.3333C7 26.2544 7.74562 27 8.66667 27H23.3333C24.2544 27 25 26.2544 25 25.3333V6.66667C25 5.74562 24.2544 5 23.3333 5H8.66667ZM5 6.66667C5 4.64105 6.64105 3 8.66667 3H23.3333C25.359 3 27 4.64105 27 6.66667V25.3333C27 27.359 25.359 29 23.3333 29H8.66667C6.64105 29 5 27.359 5 25.3333V6.66667Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6667 10C17.6667 9.44772 18.1144 9 18.6667 9H21.3333C21.8856 9 22.3333 9.44772 22.3333 10C22.3333 10.5523 21.8856 11 21.3333 11H18.6667C18.1144 11 17.6667 10.5523 17.6667 10Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6667 14C17.6667 13.4477 18.1144 13 18.6667 13H21.3333C21.8856 13 22.3333 13.4477 22.3333 14C22.3333 14.5523 21.8856 15 21.3333 15H18.6667C18.1144 15 17.6667 14.5523 17.6667 14Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66667 20C9.66667 19.4477 10.1144 19 10.6667 19H21.3333C21.8856 19 22.3333 19.4477 22.3333 20C22.3333 20.5523 21.8856 21 21.3333 21H10.6667C10.1144 21 9.66667 20.5523 9.66667 20Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66667 24C9.66667 23.4477 10.1144 23 10.6667 23H21.3333C21.8856 23 22.3333 23.4477 22.3333 24C22.3333 24.5523 21.8856 25 21.3333 25H10.6667C10.1144 25 9.66667 24.5523 9.66667 24Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 7C13.219 7 13.6667 7.44772 13.6667 8V9C13.6667 9.55228 13.219 10 12.6667 10C12.1144 10 11.6667 9.55228 11.6667 9V8C11.6667 7.44772 12.1144 7 12.6667 7Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 14C13.219 14 13.6667 14.4477 13.6667 15V16C13.6667 16.5523 13.219 17 12.6667 17C12.1144 17 11.6667 16.5523 11.6667 16V15C11.6667 14.4477 12.1144 14 12.6667 14Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0707 10C11.8483 10 11.6667 10.1816 11.6667 10.404C11.6667 10.5885 11.7926 10.751 11.9732 10.7969C11.973 10.7968 11.9734 10.7969 11.9732 10.7969L13.8485 11.2663C14.9155 11.5354 15.6667 12.4953 15.6667 13.5987C15.6667 14.925 14.5916 16.0027 13.2627 16.0027H12.1667C11.2381 16.0027 10.4923 15.487 10.077 14.8061C9.78936 14.3347 9.93839 13.7193 10.4099 13.4317C10.8813 13.144 11.4967 13.2931 11.7843 13.7645C11.8863 13.9317 12.0259 14.0027 12.1667 14.0027H13.2627C13.4858 14.0027 13.6667 13.8217 13.6667 13.5987C13.6667 13.4142 13.5407 13.2516 13.3601 13.2058C13.3603 13.2058 13.3599 13.2057 13.3601 13.2058L11.4848 12.7363C10.4178 12.4673 9.66667 11.5073 9.66667 10.404C9.66667 9.07705 10.7437 8 12.0707 8H13.1667C14.0934 8 14.8375 8.51381 15.2529 9.19267C15.5412 9.66374 15.3931 10.2793 14.922 10.5676C14.4509 10.8559 13.8353 10.7077 13.5471 10.2367C13.4452 10.0702 13.3066 10 13.1667 10H12.0707Z" fill="#31AEFF"/>
  </svg>
);

export default IconBill;
