import React from 'react';
import PropTypes from 'prop-types';

const BgEmitter = ({ color, id }) => (
  <svg width="477" height="358" viewBox="0 0 477 358" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M292.583 287.212H294.79V289.42H292.583V287.212ZM277.867 287.212H280.074V289.42H277.867V287.212ZM277.867 272.497H280.074V274.704H277.867V272.497ZM292.583 272.497H294.79V274.704H292.583V272.497ZM307.298 272.497H309.506V274.704H307.298V272.497ZM307.298 287.212H309.506V289.42H307.298V287.212ZM307.298 301.928H309.506V304.135H307.298V301.928ZM292.583 301.928H294.79V304.135H292.583V301.928ZM277.867 301.928H280.074V304.135H277.867V301.928ZM263.151 301.928H265.359V304.135H263.151V301.928ZM263.151 287.212H265.359V289.42H263.151V287.212ZM263.151 272.497H265.359V274.704H263.151V272.497ZM263.151 257.781H265.359V259.988H263.151V257.781ZM277.867 257.781H280.074V259.988H277.867V257.781ZM292.583 257.781H294.79V259.988H292.583V257.781ZM307.298 257.781H309.506V259.988H307.298V257.781ZM322.014 257.781H324.221V259.988H322.014V257.781ZM322.014 272.497H324.221V274.704H322.014V272.497ZM322.014 287.212H324.221V289.42H322.014V287.212ZM322.014 301.928H324.221V304.135H322.014V301.928ZM322.014 316.644H324.221V318.851H322.014V316.644ZM307.298 316.644H309.506V318.851H307.298V316.644ZM292.583 316.644H294.79V318.851H292.583V316.644ZM277.867 316.644H280.074V318.851H277.867V316.644ZM263.151 316.644H265.359V318.851H263.151V316.644ZM248.436 316.644H250.643V318.851H248.436V316.644ZM248.436 301.928H250.643V304.135H248.436V301.928ZM248.436 287.212H250.643V289.42H248.436V287.212ZM248.436 272.497H250.643V274.704H248.436V272.497ZM248.436 257.781H250.643V259.988H248.436V257.781ZM248.436 243.065H250.643V245.273H248.436V243.065ZM263.151 243.065H265.359V245.273H263.151V243.065ZM277.867 243.065H280.074V245.273H277.867V243.065ZM292.583 243.065H294.79V245.273H292.583V243.065ZM307.298 243.065H309.506V245.273H307.298V243.065ZM322.014 243.065H324.221V245.273H322.014V243.065Z" fill={color}/>
    <circle opacity="0.3" cx="301.436" cy="175.065" r="175" fill={`url(#${id}paint0_linear)`}/>
    <path d="M464.936 181.565C464.936 278.218 387.03 356.565 290.936 356.565C194.841 356.565 116.936 278.218 116.936 181.565C116.936 84.9128 194.841 6.56531 290.936 6.56531C387.03 6.56531 464.936 84.9128 464.936 181.565Z" stroke={`url(#${id}paint1_linear)`}/>
    <path d="M44.5826 162.212H46.79V164.42H44.5826V162.212ZM29.8669 162.212H32.0743V164.42H29.8669V162.212ZM29.8669 147.497H32.0743V149.704H29.8669V147.497ZM44.5826 147.497H46.79V149.704H44.5826V147.497ZM59.2983 147.497H61.5056V149.704H59.2983V147.497ZM59.2983 162.212H61.5056V164.42H59.2983V162.212ZM59.2983 176.928H61.5056V179.135H59.2983V176.928ZM44.5826 176.928H46.79V179.135H44.5826V176.928ZM29.8669 176.928H32.0743V179.135H29.8669V176.928ZM15.1512 176.928H17.3586V179.135H15.1512V176.928ZM15.1512 162.212H17.3586V164.42H15.1512V162.212ZM15.1512 147.497H17.3586V149.704H15.1512V147.497ZM15.1512 132.781H17.3586V134.988H15.1512V132.781ZM29.8669 132.781H32.0743V134.988H29.8669V132.781ZM44.5826 132.781H46.79V134.988H44.5826V132.781ZM59.2983 132.781H61.5056V134.988H59.2983V132.781ZM74.014 132.781H76.2213V134.988H74.014V132.781ZM74.014 147.497H76.2213V149.704H74.014V147.497ZM74.014 162.212H76.2213V164.42H74.014V162.212ZM74.014 176.928H76.2213V179.135H74.014V176.928ZM74.014 191.644H76.2213V193.851H74.014V191.644ZM59.2983 191.644H61.5056V193.851H59.2983V191.644ZM44.5826 191.644H46.79V193.851H44.5826V191.644ZM29.8669 191.644H32.0743V193.851H29.8669V191.644ZM15.1512 191.644H17.3586V193.851H15.1512V191.644ZM0.435547 191.644H2.6429V193.851H0.435547V191.644ZM0.435547 176.928H2.6429V179.135H0.435547V176.928ZM0.435547 162.212H2.6429V164.42H0.435547V162.212ZM0.435547 147.497H2.6429V149.704H0.435547V147.497ZM0.435547 132.781H2.6429V134.988H0.435547V132.781ZM0.435547 118.065H2.6429V120.273H0.435547V118.065ZM15.1512 118.065H17.3586V120.273H15.1512V118.065ZM29.8669 118.065H32.0743V120.273H29.8669V118.065ZM44.5826 118.065H46.79V120.273H44.5826V118.065ZM59.2983 118.065H61.5056V120.273H59.2983V118.065ZM74.014 118.065H76.2213V120.273H74.014V118.065Z" fill={color}/>
    <defs>
      <linearGradient id={`${id}paint0_linear`} x1="544.936" y1="-72.4347" x2="467.436" y2="169.565" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
      <linearGradient id={`${id}paint1_linear`} x1="360.936" y1="23.0653" x2="158.936" y2="182.065" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

BgEmitter.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};

BgEmitter.dafaultProps = {
  color: '#000',
};

export default BgEmitter;
