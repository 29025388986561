import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import useIntersector from './UseIntersector';

const pageBackground = {
  home: {
    backgroundColor: '#3A2870',
    backgroundImage: `url(/images/card-background-pattern-purple.png)`,
  },
  'abrir-empresa': {
    backgroundColor: '#306E97',
    backgroundImage: `url(/images/card-background-pattern-blue.png)`,
  },
  'trocar-contador': {
    backgroundColor: '#30746A',
    backgroundImage: `url(/images/card-background-pattern-green.png)`,
  },
};

const CardBackground = ({ page, children, ...rest }) => {

  const reference = useRef();

  const isIntersecting = useIntersector({ref: reference});

  const useStyles = makeStyles({
    root: {
      width: '100%',
      height: '490px',
      borderRadius: '16px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      // ...pageBackground[page],
      backgroundColor: pageBackground[page].backgroundColor,
      backgroundImage: isIntersecting ? pageBackground[page].backgroundImage : '',

      '@media screen and (max-width: 970px)': {
        height: 'auto',
        padding: '30px 0',
      },
    },
  });
  const classes = useStyles();

  return (
    <>
    <div ref={reference} />
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
    </>
  );
};

CardBackground.defaultProps = {
  page: 'home',
  children: <></>,
  rest: {},
};

CardBackground.propTypes = {
  page: PropTypes.string,
  children: PropTypes.element,
  rest: PropTypes.shape()
};

export default CardBackground;
