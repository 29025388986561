import React from 'react';

const IconPerson = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0149 13.4051C26.3521 12.7422 25.2774 12.7422 24.6145 13.4051C23.9517 14.0679 23.9517 15.1426 24.6145 15.8055C25.2774 16.4683 26.3521 16.4683 27.0149 15.8055C27.6778 15.1426 27.6778 14.0679 27.0149 13.4051ZM23.2003 11.9909C24.6442 10.547 26.9852 10.547 28.4291 11.9909C29.873 13.4348 29.873 15.7758 28.4291 17.2197C26.9852 18.6636 24.6442 18.6636 23.2003 17.2197C21.7564 15.7758 21.7564 13.4348 23.2003 11.9909Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2284 8.59022C16.9969 7.35876 15.0003 7.35877 13.7689 8.59021C12.5374 9.82167 12.5374 11.8183 13.7689 13.0497C15.0004 14.2812 16.9969 14.2812 18.2284 13.0497C19.4598 11.8182 19.4598 9.82166 18.2284 8.59022ZM19.6421 7.1755C17.6296 5.1635 14.367 5.16367 12.3547 7.176C10.3422 9.18851 10.3422 12.4514 12.3547 14.4639C14.3672 16.4764 17.6301 16.4764 19.6426 14.4639C21.6551 12.4514 21.6546 9.188 19.6421 7.1755C19.6419 7.17533 19.6423 7.17566 19.6421 7.1755Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.3829 13.4051C6.72005 12.7422 5.64536 12.7422 4.98251 13.4051C4.31966 14.0679 4.31966 15.1426 4.98251 15.8055C5.64536 16.4683 6.72005 16.4683 7.3829 15.8055C8.04575 15.1426 8.04575 14.0679 7.3829 13.4051ZM3.5683 11.9909C5.0122 10.547 7.35321 10.547 8.79711 11.9909C10.241 13.4348 10.241 15.7758 8.79711 17.2197C7.35321 18.6636 5.0122 18.6636 3.5683 17.2197C2.1244 15.7758 2.1244 13.4348 3.5683 11.9909Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.264 20.5386C25.264 19.9863 25.7117 19.5386 26.264 19.5386H27.332C29.7256 19.5386 31.6654 21.4783 31.6654 23.872V25.3333C31.6654 25.8856 31.2176 26.3333 30.6654 26.3333C30.1131 26.3333 29.6654 25.8856 29.6654 25.3333V23.872C29.6654 22.5829 28.6211 21.5386 27.332 21.5386H26.264C25.7117 21.5386 25.264 21.0909 25.264 20.5386Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66536 21.5386C3.37632 21.5386 2.33203 22.5829 2.33203 23.872V25.3333C2.33203 25.8856 1.88432 26.3333 1.33203 26.3333C0.779746 26.3333 0.332031 25.8856 0.332031 25.3333V23.872C0.332031 21.4783 2.27175 19.5386 4.66536 19.5386H5.73336C6.28565 19.5386 6.73336 19.9863 6.73336 20.5386C6.73336 21.0909 6.28565 21.5386 5.73336 21.5386H4.66536Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.8787 23.1986C7.8787 20.069 10.4157 17.532 13.5454 17.532H18.4507C21.5803 17.532 24.1174 20.069 24.1174 23.1986V25.3333C24.1174 25.8856 23.6696 26.3333 23.1174 26.3333C22.5651 26.3333 22.1174 25.8856 22.1174 25.3333V23.1986C22.1174 21.1736 20.4757 19.532 18.4507 19.532H13.5454C11.5203 19.532 9.8787 21.1736 9.8787 23.1986V25.3333C9.8787 25.8856 9.43098 26.3333 8.8787 26.3333C8.32641 26.3333 7.8787 25.8856 7.8787 25.3333V23.1986Z" fill="#31AEFF"/>
  </svg>
);

export default IconPerson;
