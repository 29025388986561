import React from 'react';

const IconDocument = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 7.5C6.375 5.22118 8.22118 3.375 10.5 3.375H21.258C22.3517 3.375 23.4013 3.81026 24.1745 4.5835L28.4165 8.82551C29.1897 9.59874 29.625 10.6483 29.625 11.742V28.5C29.625 30.7788 27.7788 32.625 25.5 32.625H10.5C8.22118 32.625 6.375 30.7788 6.375 28.5V7.5ZM10.5 5.625C9.46382 5.625 8.625 6.46382 8.625 7.5V28.5C8.625 29.5362 9.46382 30.375 10.5 30.375H25.5C26.5362 30.375 27.375 29.5362 27.375 28.5V11.742C27.375 11.2457 27.1773 10.7683 26.8255 10.4165L22.5835 6.17449C22.2317 5.82274 21.7543 5.625 21.258 5.625H10.5Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 3.375C21.6213 3.375 22.125 3.87868 22.125 4.5V10.5C22.125 10.7067 22.2933 10.875 22.5 10.875H28.5C29.1213 10.875 29.625 11.3787 29.625 12C29.625 12.6213 29.1213 13.125 28.5 13.125H22.5C21.0507 13.125 19.875 11.9493 19.875 10.5V4.5C19.875 3.87868 20.3787 3.375 21 3.375Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 16.5C10.875 15.8787 11.3787 15.375 12 15.375H21C21.6213 15.375 22.125 15.8787 22.125 16.5C22.125 17.1213 21.6213 17.625 21 17.625H12C11.3787 17.625 10.875 17.1213 10.875 16.5Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 21C10.875 20.3787 11.3787 19.875 12 19.875H21C21.6213 19.875 22.125 20.3787 22.125 21C22.125 21.6213 21.6213 22.125 21 22.125H12C11.3787 22.125 10.875 21.6213 10.875 21Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 25.5C10.875 24.8787 11.3787 24.375 12 24.375H18.495C19.1163 24.375 19.62 24.8787 19.62 25.5C19.62 26.1213 19.1163 26.625 18.495 26.625H12C11.3787 26.625 10.875 26.1213 10.875 25.5Z" fill="#31AEFF"/>
  </svg>
);

export default IconDocument;
