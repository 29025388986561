import React from 'react';
import PropTypes from 'prop-types';

const BgManagement = ({ color, id }) => (
  <svg width="415" height="358" viewBox="0 0 415 358" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.3" cx="229.155" cy="182.418" r="175" transform="rotate(-180 229.155 182.418)" fill={`url(#${id}paint0_linear)`}/>
    <path d="M65.6553 175.918C65.6553 79.2655 143.56 0.918006 239.655 0.918015C335.75 0.918023 413.655 79.2655 413.655 175.918C413.655 272.571 335.75 350.918 239.655 350.918C143.56 350.918 65.6553 272.571 65.6553 175.918Z" stroke={`url(#${id}paint1_linear)`}/>
    <path d="M44.3023 103.565H46.5097V105.772H44.3023V103.565ZM29.5866 103.565H31.794V105.772H29.5866V103.565ZM29.5866 88.8494H31.794V91.0568H29.5866V88.8494ZM44.3023 88.8494H46.5097V91.0568H44.3023V88.8494ZM59.018 88.8494H61.2254V91.0568H59.018V88.8494ZM59.018 103.565H61.2254V105.772H59.018V103.565ZM59.018 118.281H61.2254V120.488H59.018V118.281ZM44.3023 118.281H46.5097V120.488H44.3023V118.281ZM29.5866 118.281H31.794V120.488H29.5866V118.281ZM14.871 118.281H17.0783V120.488H14.871V118.281ZM14.871 103.565H17.0783V105.772H14.871V103.565ZM14.871 88.8494H17.0783V91.0568H14.871V88.8494ZM14.871 74.1337H17.0783V76.3411H14.871V74.1337ZM29.5866 74.1337H31.794V76.3411H29.5866V74.1337ZM44.3023 74.1337H46.5097V76.3411H44.3023V74.1337ZM59.018 74.1337H61.2254V76.3411H59.018V74.1337ZM73.7337 74.1337H75.9411V76.3411H73.7337V74.1337ZM73.7337 88.8494H75.9411V91.0568H73.7337V88.8494ZM73.7337 103.565H75.9411V105.772H73.7337V103.565ZM73.7337 118.281H75.9411V120.488H73.7337V118.281ZM73.7337 132.996H75.9411V135.204H73.7337V132.996ZM59.018 132.996H61.2254V135.204H59.018V132.996ZM44.3023 132.996H46.5097V135.204H44.3023V132.996ZM29.5866 132.996H31.794V135.204H29.5866V132.996ZM14.871 132.996H17.0783V135.204H14.871V132.996ZM0.155273 132.996H2.36263V135.204H0.155273V132.996ZM0.155273 118.281H2.36263V120.488H0.155273V118.281ZM0.155273 103.565H2.36263V105.772H0.155273V103.565ZM0.155273 88.8494H2.36263V91.0568H0.155273V88.8494ZM0.155273 74.1337H2.36263V76.3411H0.155273V74.1337ZM0.155273 59.418H2.36263V61.6254H0.155273V59.418ZM14.871 59.418H17.0783V61.6254H14.871V59.418ZM29.5866 59.418H31.794V61.6254H29.5866V59.418ZM44.3023 59.418H46.5097V61.6254H44.3023V59.418ZM59.018 59.418H61.2254V61.6254H59.018V59.418ZM73.7337 59.418H75.9411V61.6254H73.7337V59.418Z" fill={color}/>
    <defs>
      <linearGradient id={`${id}paint0_linear`} x1="472.655" y1="-65.082" x2="395.155" y2="176.918" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
      <linearGradient id={`${id}paint1_linear`} x1="169.655" y1="334.418" x2="371.655" y2="175.418" gradientUnits="userSpaceOnUse">
        <stop stop-color={color}/>
        <stop offset="1" stop-color={color} stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
);

BgManagement.propTypes = {
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};

BgManagement.dafaultProps = {
  color: '#000',
};

export default BgManagement;
