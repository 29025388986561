import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import PagesMetaData from '../../static/files/PagesMetaData.json';
import OrganizationSchema from '../../static/files/OrganizationSchema.json';
import OpenCompanySchema from '../../static/files/OpenCompanySchema.json';
import SwitchAccountantSchema from '../../static/files/SwitchAccountantSchema.json';
import Favicon from '../../../static/favicon.ico';

const SEO = ({ page }) => {
  const includeLdJSON = (pageName, schema) => (
    page === pageName ? (
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    ) : ''
  );

  return (
    <Helmet htmlAttributes={{ lang: 'pt-br' }}>
      <meta charSet="utf-8" />
      <title>{PagesMetaData[page].title}</title>
      <meta name="title" content={PagesMetaData[page].title} />
      <meta name="description" content={PagesMetaData[page].description} />
      <link rel="shortcut icon" href="data:image/x-icon;," type="image/x-icon" />
      <link rel="icon" href={Favicon} />
      <meta name="facebook-domain-verification" content={process.env.GATSBY_FACEBOOK_VERIFICATION} />

      {/* Adicionando o código do Google Ads */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16704555705" />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', 'AW-16704555705');
        `}
      </script>
      {includeLdJSON('home', OrganizationSchema)}
      {includeLdJSON('trocar-contador', OpenCompanySchema)}
      {includeLdJSON('trocar-contador-wdec', OpenCompanySchema)}
      {includeLdJSON('abrir-empresa', SwitchAccountantSchema)}
    </Helmet>
  );
};

SEO.propTypes = {
  page: PropTypes.string.isRequired,
};

export default SEO;
