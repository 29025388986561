import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useNetworkStatus } from 'react-adaptive-hooks/network';
import Employee1 from '../../static/images/employee-png/funcionario-conube-1.png';
import Employee2 from '../../static/images/employee-png/funcionario-conube-2.png';
import Employee3 from '../../static/images/employee-png/funcionario-conube-3.png';
import Employee5 from '../../static/images/employee-png/funcionario-conube-5.png';
import Employee6 from '../../static/images/employee-png/funcionario-conube-6.png';
import Employee7 from '../../static/images/employee-png/funcionario-conube-7.png';
import Employee8 from '../../static/images/employee-png/funcionario-conube-8.png';
import EmployeeWebp1 from '../../static/images/employee-webp/funcionario-conube-1.webp';
import EmployeeWebp2 from '../../static/images/employee-webp/funcionario-conube-2.webp';
import EmployeeWebp3 from '../../static/images/employee-webp/funcionario-conube-3.webp';
import EmployeeWebp5 from '../../static/images/employee-webp/funcionario-conube-5.webp';
import EmployeeWebp6 from '../../static/images/employee-webp/funcionario-conube-6.webp';
import EmployeeWebp7 from '../../static/images/employee-webp/funcionario-conube-7.webp';
import EmployeeWebp8 from '../../static/images/employee-webp/funcionario-conube-8.webp';
import SquareEmojiHands from '../../static/images/quadrado-maos-alto.png';
import SquareEmojiBeach from '../../static/images/quadrado-praia.png';
import PurpleHeart from '../../static/images/coracao-roxo.png';
import TenYearAnniversary from '../../static/images/employee-png/10years.png';
import TenYearAnniversaryWebp from '../../static/images/employee-webp/10years.webp';


const ITEM_SIZE = '136px';
const TEN_YEAR_ITEM_SIZE = '281px';
const BORDER_RADIUS = '12px';
const BORDER_COLOR = '#8645FF';
const HOVER_EFFECT = {
  transform: 'scale(1.05)',
  boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
};

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, min-content)',
    gap: '11px',
    borderRadius: BORDER_RADIUS,
    padding: '20px',
  },
  
  hoverEffect: {
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': HOVER_EFFECT,
  },
  
  boxItem: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    background: '#573DA8',
    border: `1px solid ${BORDER_COLOR}`,
    borderRadius: BORDER_RADIUS,
    ...HOVER_EFFECT,
  },
  
  boxItemHeart: {
    width: '140px',
    height: '140px',
    borderRadius: BORDER_RADIUS,
    ...HOVER_EFFECT,
  },
  boxItemTenYearAnniversary: {
    width: TEN_YEAR_ITEM_SIZE,
    height: '140px',
    border: `1px solid ${BORDER_COLOR}`,
    borderRadius: BORDER_RADIUS,
    ...HOVER_EFFECT,
  },

  image: {
    width: ITEM_SIZE,
    height: ITEM_SIZE,
    objectFit: 'cover',
    borderRadius: BORDER_RADIUS,
  },

  imageTenYearAnniversary: {
    width: TEN_YEAR_ITEM_SIZE,
    height: '140px',
    objectFit: 'cover',
    borderRadius: BORDER_RADIUS,
  },

  heart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '30px',
      height: '30px',
    },
  },
});

const EmployeePictures = () => {
  const classes = useStyles();
  const { effectiveConnectionType } = useNetworkStatus();

  function getImageByConnection(firstOption, secondOption) {
    const option = ['2g', 'slow-2g'].includes(effectiveConnectionType) ? secondOption : firstOption;
    return <img className={classes.image} src={option} alt="Foto de funcionário da Conube" />;
  }

  function getImageByConnectionTenYearAnniversary(firstOption, secondOption) {
    const option = ['2g', 'slow-2g'].includes(effectiveConnectionType) ? secondOption : firstOption;
    return <img className={classes.imageTenYearAnniversary} src={option} alt="Foto comemorativas dos 10 anos da Conube" />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.boxItem}>
        <img className={classes.image} src={SquareEmojiHands} alt="Emoji mãos para o alto comemorando" />
      </Box>
      <Box className={classes.boxItem}>{getImageByConnection(Employee1, EmployeeWebp1)}</Box>
      <Box className={classes.boxItem} />
      <Box className={classes.boxItem}>{getImageByConnection(Employee2, EmployeeWebp2)}</Box>

      <Box className={classes.boxItemTenYearAnniversary} style={{ gridColumn: 'span 2' }}>
        {getImageByConnectionTenYearAnniversary(TenYearAnniversary, TenYearAnniversaryWebp)}
      </Box>

      <Box className={classes.boxItem}>{getImageByConnection(Employee3, EmployeeWebp3)}</Box>
      <Box className={classes.boxItem} />
      <Box className={classes.boxItem}>{getImageByConnection(Employee5, EmployeeWebp5)}</Box>
      <Box className={`${classes.boxItemHeart} ${classes.heart}`}>
        <img src={PurpleHeart} alt="Coração roxo" />
      </Box>
      <Box className={classes.boxItem}>{getImageByConnection(Employee6, EmployeeWebp6)}</Box>
      <Box className={classes.boxItem}>{getImageByConnection(Employee7, EmployeeWebp7)}</Box>
      <Box />
      <Box className={classes.boxItem}>{getImageByConnection(Employee8, EmployeeWebp8)}</Box>
      <Box className={classes.boxItem}>
        <img className={classes.image} src={SquareEmojiBeach} alt="Emoji praia" />
      </Box>
      <Box />
    </Box>
  );
};

export default EmployeePictures;
