import React from 'react';

const IconPaperCalendar = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.332 1.66663C21.8843 1.66663 22.332 2.11434 22.332 2.66663V7.99996C22.332 8.55224 21.8843 8.99996 21.332 8.99996C20.7797 8.99996 20.332 8.55224 20.332 7.99996V2.66663C20.332 2.11434 20.7797 1.66663 21.332 1.66663Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.668 1.66663C11.2203 1.66663 11.668 2.11434 11.668 2.66663V7.99996C11.668 8.55224 11.2203 8.99996 10.668 8.99996C10.1157 8.99996 9.66797 8.55224 9.66797 7.99996V2.66663C9.66797 2.11434 10.1157 1.66663 10.668 1.66663Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 11.4477 3.44772 11 4 11H28C28.5523 11 29 11.4477 29 12C29 12.5523 28.5523 13 28 13H4C3.44772 13 3 12.5523 3 12Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 7.99992C3 5.9743 4.64105 4.33325 6.66667 4.33325H25.3333C27.359 4.33325 29 5.9743 29 7.99992V25.3333C29 27.3589 27.359 28.9999 25.3333 28.9999H6.66667C4.64105 28.9999 3 27.3589 3 25.3333V7.99992ZM6.66667 6.33325C5.74562 6.33325 5 7.07887 5 7.99992V25.3333C5 26.2543 5.74562 26.9999 6.66667 26.9999H25.3333C26.2544 26.9999 27 26.2543 27 25.3333V7.99992C27 7.07887 26.2544 6.33325 25.3333 6.33325H6.66667Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 17.3333C8.33203 16.781 8.77975 16.3333 9.33203 16.3333H22.6654C23.2176 16.3333 23.6654 16.781 23.6654 17.3333C23.6654 17.8855 23.2176 18.3333 22.6654 18.3333H9.33203C8.77975 18.3333 8.33203 17.8855 8.33203 17.3333Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33203 22.6666C8.33203 22.1143 8.77975 21.6666 9.33203 21.6666H15.9987C16.551 21.6666 16.9987 22.1143 16.9987 22.6666C16.9987 23.2189 16.551 23.6666 15.9987 23.6666H9.33203C8.77975 23.6666 8.33203 23.2189 8.33203 22.6666Z" fill="#31AEFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6654 23.6666C22.4816 23.6666 22.332 23.8162 22.332 24V28C22.332 28.5522 21.8843 29 21.332 29C20.7797 29 20.332 28.5522 20.332 28V24C20.332 22.7117 21.3771 21.6666 22.6654 21.6666H27.9987C28.551 21.6666 28.9987 22.1143 28.9987 22.6666C28.9987 23.2189 28.551 23.6666 27.9987 23.6666H22.6654Z" fill="#31AEFF"/>
  </svg>
);

export default IconPaperCalendar;
